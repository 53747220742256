import React from "react"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import NavBar from "../components/NavBar"
import Header from "../components/pages/nous-trouver/Header"
import Map from "../components/pages/nous-trouver/NousTrouverMap"
import SEO from "../components/Seo"
import "./nous-trouver.scss"

const NousTrouver = () => (
  <Layout>
    <SEO
      title="Nous Trouver"
      description="Trouver le collecteur le plus proche de chez vous"
      keywords={["consigne", "emballage", "collecteur", "restauration"]}
    />
    <div className="nous-trouver__background">
      <NavBar activePage="nous-trouver" />
      <Header />
      <Map />
      <Footer />
    </div>
  </Layout>
)

export default NousTrouver
