import * as React from "react"
import Map from "../../Map"
import "./NousTrouverMap.scss"

const NousTrouverMap: React.FunctionComponent = () => {
  return (
    // Important! Always set the container height explicitly
    <section className="nous-trouver__map">
      <p className="paragraph paragraph--top">
        Trouvez un point de collecte ou un partenaire autour de vous !
      </p>
      <section className="input hidden-for-the-moment">
        <div className="input__elements">
          <input
            className="map-input"
            type="text"
            name=""
            id=""
            placeholder="Adresse, métro..."
          />
          <button
            className="button button--color-pink"
            onClick={onClickChercher}
          >
            Chercher
          </button>
        </div>
      </section>
      <section className="map__elements">
        <div className="map">
          <Map />
        </div>
      </section>
    </section>
  )
}

export default NousTrouverMap

const onClickChercher = () => {}
