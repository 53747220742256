import * as React from "react"
import "./Header.scss"

const Header: React.FunctionComponent = () => {
  return (
    <section className="nous-trouver__header">
      <h1 className="heading heading--1">Nous Trouver</h1>
    </section>
  )
}

export default Header
